import { Stack } from "flicket-ui";
import { AudienceLocation } from "~features/reports/reporting/components/dashboards/audience/audience.location";
import { ReportingFilters } from "~features/reports/reporting/components/filters/ReportingFilters";
import { AudienceAge } from "~features/reports/reporting/components/dashboards/audience/audience.age";
import { GoogleAnalyticsReportType } from "~graphql/typed-document-nodes";
import { AudienceHeard } from "~features/reports/reporting/components/dashboards/audience/audience.heard";
import { AudiencePurchase } from "~features/reports/reporting/components/dashboards/audience/audience.purchase";
import { AudienceSales } from "~features/reports/reporting/components/dashboards/audience/event/audience.sales";
import { AudienceTag } from "../audience.tags";
import { AudienceReferrer } from "../audience.referrer";
import { AudienceHighestValueCustomers } from "../audience.highestValueCustomers";
import { AudienceRepeatPurchaser } from "../audience.repeatPurchaser";
import { ResponsiveGrid } from "~components";
import { AudienceGender } from "~features/reports/reporting/components/dashboards/audience/audience.gender";
import { AudienceTraffic } from "~features/reports/reporting/components/dashboards/audience/audience.traffic";
import { AudienceDevice } from "~features/reports/reporting/components/dashboards/audience/audience.device";
import { OverallReportParams } from "~features/reports/reporting/types";
import { AudienceStatistics } from "./audience.statistics";

export const OverallAudienceIndex = ({ params }: OverallReportParams) => {
  const googleInput = {
    sourceId: params.sourceId,
    startDate: params.startDate,
    endDate: params.endDate,
    source: params.source,
  };
  return (
    <Stack direction="vertical" gap={3}>
      <ReportingFilters />
      <AudienceStatistics params={params} />
      <AudiencePurchase params={params} />
      <AudienceSales params={params} />
      <AudienceLocation params={params} />
      <AudienceAge params={params} />
      {/*
          we only want to add the gap when the content exists, hence we need to remove this gap on the container and add it to the children
        */}
      <ResponsiveGrid mt={"-24px" as any}>
        <AudienceGender params={params} mt={3} />
        <AudienceDevice
          params={{ ...googleInput, type: GoogleAnalyticsReportType.Device }}
          mt={3}
        />
      </ResponsiveGrid>
      <AudienceTraffic
        params={{
          ...googleInput,
          type: GoogleAnalyticsReportType.TrafficSource,
        }}
      />
      <AudienceHeard params={params} />
      <AudienceTag params={params} />
      <AudienceHighestValueCustomers params={params} />
      <AudienceReferrer params={params} />
      <AudienceRepeatPurchaser params={params} />
    </Stack>
  );
};
