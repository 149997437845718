import { useQuery } from "~hooks/useQuery";
import { Permission, TopTagsDocument } from "~graphql/typed-document-nodes";
import { ReportingCard } from "~features/reports/reporting/components/dashboards/ReportingCard";
import { Text, UnderlineButton } from "flicket-ui";
import React from "react";
import { List } from "~components/common/List/List";
import { stringify } from "qs";
import { apiUrl } from "~config";
import { useOrganization, usePermissions } from "~hooks";
import { downloadFile } from "~lib";
import { BaseReportParams } from "~features/reports/reporting/types";

export const AudienceTag = ({ params }: BaseReportParams) => {
  const { organization } = useOrganization();
  const { hasPermissions } = usePermissions();

  const { data, error, isLoading } = useQuery(TopTagsDocument, params);
  if (isLoading || error || data?.topTags.length === 0) {
    return <></>;
  }

  const onDownload = () => {
    const query = stringify({
      "flicket-org-id": organization?.id,
      ...params,
    });

    const url = `${apiUrl}/export/reporting/top-tags?${query}`;

    const name = "";

    downloadFile(url, name);
  };

  return (
    <ReportingCard
      title={"Tags"}
      TopRightElement={
        hasPermissions(Permission.ReportingExportTopTags) ? (
          <UnderlineButton
            display={"inline-block"}
            color="N800"
            textAlign={"left"}
            fontSize={3}
            fontWeight={"regular"}
            onClick={onDownload}
          >
            <Text variant="regular">Download all</Text>
          </UnderlineButton>
        ) : null
      }
    >
      <List mb={2}>
        {data.topTags.map((item) => (
          <List.ColumnItems key={item.tag} noTopBorder>
            <List.Column>
              <Text variant="regular">{item.tag}</Text>
            </List.Column>
            <List.Column justifyContent={"flex-end"}>
              <Text variant="regular">{item.count}</Text>
            </List.Column>
          </List.ColumnItems>
        ))}
      </List>
    </ReportingCard>
  );
};
