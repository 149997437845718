import { Box, Flex, formatPrice, Text } from "flicket-ui";
import { Icon, Status } from "~components";
import { Card, Grid } from ".";
import { useQuery } from "~hooks/useQuery";
import { SummaryOverviewDocument } from "~graphql/typed-document-nodes";
import { IconName } from "~components/common/Icon/Icon";
import { useOrganization } from "~hooks";
import { OrganizationFeatures } from "~lib/features";

export function OverviewSummary() {
  const { data, error, isLoading } = useQuery(SummaryOverviewDocument);
  const { hasFeature } = useOrganization();

  const summaryOverview = data?.summaryOverviewV2;

  const showFaceValue = !hasFeature(
    OrganizationFeatures.NetInsteadOfValueValue
  );

  type OverviewItem = {
    title: string;
    icon: IconName;
    key: keyof typeof summaryOverview;
  };

  const overviewItems: OverviewItem[] = [
    {
      title: "Tickets sold today",
      icon: "my-tickets",
      key: "ticketsSoldToday",
    },
    {
      title: "Tickets sold yesterday",
      icon: "my-tickets",
      key: "ticketsSoldYesterday",
    },
    { title: "Total tickets sold", icon: "my-tickets", key: "ticketsSold" },
    ...(showFaceValue
      ? ([
          {
            title: "Face Value Total",
            icon: "trending_up",
            key: "totalFaceRevenue",
          },
          {
            title: "Face Value Today",
            icon: "trending_up",
            key: "faceRevenueToday",
          },
          {
            title: "Face Value Yesterday",
            icon: "trending_up",
            key: "faceRevenueYesterday",
          },
        ] as OverviewItem[])
      : ([
          {
            title: "Total net revenue",
            icon: "trending_up",
            key: "totalNetRevenue",
          },
          {
            title: "Today's net revenue",
            icon: "trending_up",
            key: "todayNetRevenue",
          },
          {
            title: "Yesterday's net revenue",
            icon: "trending_up",
            key: "yesterdayNetRevenue",
          },
        ] as OverviewItem[])),
    // Face value

    { title: "Orders", icon: "orders", key: "orders" },
    {
      title: "Total tickets issued",
      icon: "my-tickets",
      key: "ticketsIssued",
    },
    { title: "Cart abandonment", icon: "cart", key: "cartAbandonment" },
    {
      title: "Returning customers",
      icon: "customer",
      key: "returningCustomers",
    },
    { title: "Total comps issued", icon: "my-tickets", key: "ticketsComp" },
    {
      title: "Memberships issued",
      icon: "membership",
      key: "membershipsIssued",
    },
    {
      title: "Memberships renewed",
      icon: "membership",
      key: "membershipsRenewed",
    },
    { title: "Add-ons issued", icon: "add-cart", key: "addonsIssued" },
    {
      title: "Part paid tickets",
      icon: "my-tickets",
      key: "ticketsPartPaid",
    },
    {
      title: "Part paid memberships",
      icon: "my-tickets",
      key: "membershipsPartPaid",
    },
    { title: "Part paid orders", icon: "orders", key: "ordersPartPaid" },
  ];

  return (
    <>
      <Card
        title={"Overall summary"}
        position="relative"
        boxShadow={{ _: "none", md: "sm" }}
        p={{ _: 0, md: 4 }}
      >
        <Status loading={isLoading} error={error}>
          {summaryOverview?.ticketsIssued > 0 ||
          summaryOverview?.membershipsIssued > 0 ||
          summaryOverview?.addonsIssued > 0 ? (
            <Grid>
              {overviewItems.map(({ title, icon, key }) => {
                if (summaryOverview?.[key] === 0) {
                  return;
                } else {
                  return (
                    <Box
                      bg="P100"
                      borderRadius="sm"
                      p={2}
                      flex={1}
                      key={title}
                      mr={{ _: "6/4", md: 0 }}
                      minWidth={{ _: 260, md: 220 }}
                      mb={2}
                    >
                      <Flex alignItems="center" mb={2}>
                        <Flex
                          as="span"
                          borderRadius="full"
                          bg="P300"
                          width={32}
                          height={32}
                          variant="center"
                          mr={1}
                        >
                          <Icon icon={icon} color="white" fontSize={4} />
                        </Flex>
                        <Text fontWeight="extraBold" color="N500">
                          {title}
                        </Text>
                      </Flex>
                      <Text fontWeight="extraBold" fontSize={6} color="N600">
                        {key === "totalNetRevenue" ||
                        key === "todayNetRevenue" ||
                        key === "yesterdayNetRevenue" ||
                        key === "totalFaceRevenue" ||
                        key === "faceRevenueToday" ||
                        key === "faceRevenueYesterday"
                          ? formatPrice(summaryOverview?.[key])
                          : summaryOverview?.[key]?.toLocaleString()}
                      </Text>
                    </Box>
                  );
                }
              })}
            </Grid>
          ) : (
            <Flex height={150} variant="center" width={1}>
              There is no activity to show
            </Flex>
          )}
        </Status>
      </Card>
    </>
  );
}

export default OverviewSummary;
