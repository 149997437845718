import { useQuery } from "~hooks/useQuery";
import { TopReferrersDocument } from "~graphql/typed-document-nodes";
import { ReportingCard } from "~features/reports/reporting/components/dashboards/ReportingCard";
import { Text } from "flicket-ui";
import React from "react";
import { List } from "~components/common/List/List";
import { BaseReportParams } from "~features/reports/reporting/types";

export const AudienceReferrer = ({ params }: BaseReportParams) => {
  const { data, error, isLoading } = useQuery(TopReferrersDocument, params);

  if (isLoading || error || data?.topReferrers.length === 0) {
    return <></>;
  }

  return (
    <ReportingCard title={"Referrers"}>
      <List mb={2}>
        {data.topReferrers.map((item) => (
          <List.ColumnItems key={item.userId} noTopBorder>
            <List.Column>
              <Text variant="regular">{item.name}</Text>
            </List.Column>
            <List.Column justifyContent={"flex-end"}>
              <Text variant="regular">{item.count}</Text>
            </List.Column>
          </List.ColumnItems>
        ))}
      </List>
    </ReportingCard>
  );
};
