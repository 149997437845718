import { Card } from "~components/reports";
import { PurchasingDaysBreakdownDocument } from "~graphql/typed-document-nodes";
import { useQuery } from "~hooks/useQuery";
import { REPORT_COLOURS } from "~components/reports/common/reportColours";
import { PurchasingDaysBreakdown } from "~components/reports/insights/PurchasingDaysBreakdown";
import { BaseReportParams } from "~features/reports/reporting/types";

export const AudiencePurchase = ({ params }: BaseReportParams) => {
  const { data, error, isLoading } = useQuery(
    PurchasingDaysBreakdownDocument,
    params
  );

  const hasAgeData = data?.purchasingDaysBreakdown.some(
    (item) => item.value > 0
  );
  if (
    isLoading ||
    error ||
    data?.purchasingDaysBreakdown.length === 0 ||
    !hasAgeData
  )
    return <></>;
  return (
    <Card title="Purchasing days breakdown">
      <PurchasingDaysBreakdown
        data={data?.purchasingDaysBreakdown}
        colour={REPORT_COLOURS.teal}
      />
    </Card>
  );
};
