import { Stack } from "flicket-ui";
import { AudiencePurchase } from "~features/reports/reporting/components/dashboards/audience/audience.purchase";
import { AudienceAge } from "~features/reports/reporting/components/dashboards/audience/audience.age";
import { AudienceLocation } from "~features/reports/reporting/components/dashboards/audience/audience.location";
import { AudienceGender } from "~features/reports/reporting/components/dashboards/audience/audience.gender";
import { ResponsiveGrid } from "~components";
import { AudienceHeard } from "~features/reports/reporting/components/dashboards/audience/audience.heard";
import { AudienceTag } from "~features/reports/reporting/components/dashboards/audience/audience.tags";
import { AudienceHighestValueCustomers } from "~features/reports/reporting/components/dashboards/audience/audience.highestValueCustomers";
import { AudienceReferrer } from "~features/reports/reporting/components/dashboards/audience/audience.referrer";
import { AudienceRepeatPurchaser } from "~features/reports/reporting/components/dashboards/audience/audience.repeatPurchaser";
import { MembershipAudienceStatistics } from "~features/reports/reporting/components/dashboards/audience/membership/audience.statistics";
import { ReportingFilters } from "~features/reports/reporting/components/filters/ReportingFilters";
import { AudienceDevice } from "~features/reports/reporting/components/dashboards/audience/audience.device";
import { AudienceTraffic } from "~features/reports/reporting/components/dashboards/audience/audience.traffic";
import { GoogleAnalyticsReportType } from "~graphql/typed-document-nodes";
import {
  MembershipReportParams,
  SeasonReportParams,
} from "~features/reports/reporting/types";

export const MembershipAudienceIndex = ({
  params,
}: MembershipReportParams | SeasonReportParams) => {
  const googleInput = {
    sourceId: params.sourceId,
    startDate: params.startDate,
    endDate: params.endDate,
    source: params.source,
    seasonId: "seasonId" in params && params.seasonId,
  };
  return (
    <Stack direction="vertical" gap={3}>
      <ReportingFilters />
      <MembershipAudienceStatistics params={params} />
      <AudiencePurchase params={params} />
      <AudienceAge params={params} />
      <AudienceLocation params={params} />
      {/*
          we only want to add the gap when the content exists, hence we need to remove this gap on the container and add it to the children
        */}
      <ResponsiveGrid mt={"-24px" as any}>
        <AudienceGender params={params} mt={3} />
        <AudienceDevice
          params={{ ...googleInput, type: GoogleAnalyticsReportType.Device }}
          mt={3}
        />
      </ResponsiveGrid>
      <AudienceTraffic
        params={{
          ...googleInput,
          type: GoogleAnalyticsReportType.TrafficSource,
        }}
      />
      <AudienceHeard params={params} />
      <AudienceTag params={params} />
      <AudienceHighestValueCustomers params={params} />
      <AudienceReferrer params={params} />
      <AudienceRepeatPurchaser params={params} />
    </Stack>
  );
};
