import { useQuery } from "~hooks/useQuery";
import {
  InsightsAgeBreakDownDocument,
  ReportType,
} from "~graphql/typed-document-nodes";
import { ReportingCard } from "~features/reports/reporting/components/dashboards/ReportingCard";
import { Box, Stack, SystemProps, Text } from "flicket-ui";
import React from "react";
import styled from "styled-components";
import { List } from "~components/common/List/List";
import { useScreenSize } from "~hooks/useScreenSize";
import { PercentBar } from "~components/common/PercentBar";
import { REPORT_COLOURS } from "~components/reports/common/reportColours";
import { AudienceGoogleButton } from "~features/reports/reporting/components/dashboards/audience/audience.google";
import { BaseReportParams } from "~features/reports/reporting/types";

export const StyleList = styled(List)`
  li:first-child:before {
    background: none;
  }
`;

const Grid = styled(Box)<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: 2fr 6fr 2fr;
  grid-template-rows: 1fr;
  grid-gap: ${(props) => (props.isMobile ? "4px" : "10px")};
  justify-content: space-between;
`;

export const AudienceAge = ({ params }: BaseReportParams) => {
  const isMobile = useScreenSize().isPhonePortrait;

  const { data, error, isLoading } = useQuery(
    InsightsAgeBreakDownDocument,
    params
  );
  const hasData = data?.insightsAgeBreakDown?.reportData.some(
    (item) => item.total > 0
  );
  if (isLoading || error || !hasData) {
    return <></>;
  }

  const title = (
    <Text>
      Age{" "}
      {data.insightsAgeBreakDown.reportType === ReportType.GoogleAnalytics && (
        <Text variant="header.S" as="span">
          (via Google Analytics)
        </Text>
      )}
    </Text>
  );

  return (
    <ReportingCard title={title} TopRightElement={<AudienceGoogleButton />}>
      <Stack direction={"vertical"}>
        {data.insightsAgeBreakDown.reportData.map((item) => (
          <StyleList key={item.ageRange}>
            <List.BaseItem key={`age-${item.ageRange}`}>
              <Grid isMobile={isMobile}>
                <Text textAlign={"start"} gridColumn={1}>
                  {item.ageRange}
                </Text>
                <PercentBar
                  minHeight={"24px"}
                  maxHeight={"24px"}
                  gridColumn={2}
                  percentage={item.percentage}
                  barColour={
                    REPORT_COLOURS.teal as SystemProps["backgroundColor"]
                  }
                  borderRadius={"none"}
                />
                <Text textAlign={"end"} gridColumn={3}>
                  {item.percentage}%
                </Text>
              </Grid>
            </List.BaseItem>
          </StyleList>
        ))}
      </Stack>
    </ReportingCard>
  );
};
